﻿.content-list() {
    padding: 0;
    list-style-type: none;

    .content-item {
        .make-row();
        margin-bottom: @line-height-computed;

        > header {
            .make-xs-column(8);
            .make-sm-column(9);
            .make-md-column(10);

            > h1 {
                margin: 0;
                font-size: @font-size-h2;
            }
        }

        > aside {
            .make-xs-column(4);
            .make-sm-column(3);
            .make-md-column(2);
        }

        > content {
            .make-xs-column(8);
            .make-sm-column(9);
            .make-md-column(10);

            > p {
                margin: (@line-height-computed / 4) 0;
            }
        }
    }
}
