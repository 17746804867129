﻿@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.portfolio-viewer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #FFF;
    background-color: #000;
    animation: fadein .3s linear;
    z-index: @zindex-modal;
    text-align: center;

    &.multi {
        padding-bottom: 40px;
    }

    &:before {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
    }

    button {
        color: inherit;
        background-color: transparent;
        border: none;
        .box-shadow(none);

        &[disabled] {
            color: @gray;
        }

        &:hover,
        &:focus {
            background-color: transparent;
            .box-shadow(none);
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 30px;
        line-height: 1;
    }

    .controls {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        font-size: 20px;
    }

    .counter {
        position: absolute;
        right: @padding-base-horizontal;
        top: 0;
        line-height: 40px;
        font-size: @font-size-base;
        font-weight: 200;
    }

    .pause {
        display: none;
    }

    &.playing {
        .pause {
            display: inline-block;
        }

        .play {
            display: none;
        }
    }
}
