﻿@font-face {
    font-family: "geomanist";
    src: url("@{font-path}geomanist-book-webfont.eot");
    src: url("@{font-path}geomanist-book-webfont.eot?#iefix") format("embedded-opentype"),
        url("@{font-path}geomanist-book-webfont.woff2") format("woff2"),
        url("@{font-path}geomanist-book-webfont.woff") format("woff"),
        url("@{font-path}geomanist-book-webfont.ttf") format("truetype"),
        url("@{font-path}geomanist-book-webfont.svg#geomanistbook") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "geomanist";
    src: url("@{font-path}geomanist-medium-webfont.eot");
    src: url("@{font-path}geomanist-medium-webfont.eot?#iefix") format("embedded-opentype"),
        url("@{font-path}geomanist-medium-webfont.woff2") format("woff2"),
        url("@{font-path}geomanist-medium-webfont.woff") format("woff"),
        url("@{font-path}geomanist-medium-webfont.ttf") format("truetype"),
        url("@{font-path}geomanist-medium-webfont.svg#geomanistmedium") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "geomanist";
    src: url("@{font-path}geomanist-light-webfont.eot");
    src: url("@{font-path}geomanist-light-webfont.eot?#iefix") format("embedded-opentype"),
        url("@{font-path}geomanist-light-webfont.woff2") format("woff2"),
        url("@{font-path}geomanist-light-webfont.woff") format("woff"),
        url("@{font-path}geomanist-light-webfont.ttf") format("truetype"),
        url("@{font-path}geomanist-light-webfont.svg#geomanistlight") format("svg");
    font-weight: 300;
    font-style: normal;
}
