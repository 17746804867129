// Core variables and mixins
@import "variables.less";
@import "Bootstrap/mixins.less";
@import "mixins.less";

// Reset and dependencies
@import "Bootstrap/normalize.less";
@import "Bootstrap/print.less";

// Core CSS
@import "Bootstrap/scaffolding.less";
@import "Bootstrap/type.less";
@import "Bootstrap/grid.less";

// Utility classes
@import "Bootstrap/utilities.less";
@import "Bootstrap/responsive-utilities.less";

// Components w/ JavaScript
@import "Bootstrap/modals.less";

@import "fonts.less";
@import "FontAwesome/font-awesome.less";
@import "utils.less";
@import "portfolio.less";

*:focus {
    outline: none;
}

/*
    Layout
*/
html, body {
    height: 100%;
}

#layout-wrapper {
    min-height: 100%;
    position: relative;
    padding-bottom: 100px;
}

#layout-main-container,
#footer {
    .container();
}

#layout-main-container {
    padding-top: (2 * @line-height-computed);
    padding-bottom: (2 * @line-height-computed);
}

#layout-main {
    .make-row();
}

#aside-first,
#aside-second {
    .make-sm-column(3);
    .make-md-column(2);
}

#layout-content {
    .make-sm-column(9);
    .make-md-column(10);
    .make-lg-column(8);
}

#body-wrapper {
    background-color: #FFF;
}

.background-image {
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 180px;
    z-index: -1;

    .overlay {
        position: absolute;
        top: 180px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: fade(#6396ab, 73%);
    }
}

/*
    Header
*/
.zone-header {
    background-color: fade(#6396ab, 73%);
}

#header {
    height: 180px;
}

#baseline {
    padding-top: 92px;
    padding-bottom: 35px;
    img {
        display: block;
        margin: 0 auto;
    }
}

#layout-navigation {
    background-color: #497183;
    color: #FFF;
    height: 70px;
}

/*
    Footer
*/
#layout-footer {
    background-color: #353f47;
    color: #FFF;
    padding: 30px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    a {
        color: inherit;
    }
}

#footer-sig {
    .make-row();
}

.zone-footer {
    .make-sm-column-push(3);
    .make-sm-column(9);
    .make-md-column-push(2);
    .make-md-column(10);
}

/*
    Forms
*/
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;

    ol {
        list-style-type: none;
        padding: 0;
    }
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: @line-height-computed;
    font-size: (@font-size-base * 1.5);
    line-height: inherit;
    color: @legend-color;
    border: 0;
    border-bottom: 1px solid @legend-border-color;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    color: @input-color;
    background-color: @input-bg;
    background-image: none;
    border: 1px solid @input-border;
    border-radius: @input-border-radius;
    margin-bottom: (@line-height-computed / 2);
}

label {
    display: block;
    text-transform: uppercase;

    &.forcheckbox {
        display: inline-block;
        padding-left: @padding-small-horizontal;
    }
}

button,
.button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    display: inline-block;
    text-transform: uppercase;
    background-color: darken(@brand-primary, 10%);
    color: #FFF;
    border: none;
    padding: @padding-base-vertical @padding-base-horizontal;
    .box-shadow(0px 5px 10px 0px @gray-dark);

    &:hover,
    &:focus {
        color: #FFF;
        background-color: @brand-primary;
        text-decoration: none;
        .box-shadow(0px 3px 8px 0px @gray-dark);
    }
}

.message {
    padding: @alert-padding;
    margin-bottom: @line-height-computed;
    border: 1px solid transparent;
    border-radius: @alert-border-radius;

    &.message-Information {
        .alert-variant(@alert-success-bg; @alert-success-border; @alert-success-text);
    }

    &.message-Warning {
        .alert-variant(@alert-warning-bg; @alert-warning-border; @alert-warning-text);
    }

    &.message-Danger {
        .alert-variant(@alert-danger-bg; @alert-danger-border; @alert-danger-text);
    }
}

/*
    Menus
*/
.menu {
    list-style-type: none;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

/*
    Nav bar
*/
.zone-navigation {
    .container();
    padding: 15px 0;

    > .widget-menu-widget {
        .make-sm-column-push(3);
        .make-sm-column(9);
        .make-md-column-push(2);
        .make-md-column(10);
        > nav {
            position: absolute;
            width: 100%;
        }
    }
}

.menu-nav-bar {
    &, ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    display: flex;
    justify-content: space-between;

    a {
        color: inherit;
        line-height: 20px;

        &:hover, &:focus {
            text-decoration: none;
            font-weight: bold;
        }
    }

    li {
        max-width: 160px;
        padding: @padding-large-vertical 0;
        text-overflow: ellipsis;
    }

    > li {
        padding-left: @padding-base-horizontal;
        padding-right: @padding-base-horizontal;
        height: 40px;
        overflow: hidden;
        transition: background-color linear .5s;

        > a {
            white-space: nowrap;
        }

        &.dropdown {
            > ul {
            }

            &.open {
                background-color: fade(#FFF, 70%);
                z-index: @zindex-navbar;
                color: @brand-primary;
                height: auto;
            }
        }
    }
}

/*
    Main menu
*/
#branding {
    display: block;
    margin-bottom: (2.5 * @line-height-computed);

    .negative {
        display: none;
    }
}

.widget-aside-first.widget-menu-widget {
    .menu {
        padding-left: 20px;
        > li {
            border-left: 4px solid transparent;
            padding: @padding-base-vertical @padding-base-horizontal;

            a {
                color: #77aabe;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            &.current,
            &:hover {
                a {
                    color: @brand-primary;
                }
            }

            &.current {
                border-left-color: #6c9aad;
            }
        }
    }
}

.widget-footer.widget-menu-widget {
    .pull-left();
    .menu {
        font-size: @font-size-small;
        > li {
            display: inline-block;
            padding: 0 @padding-small-horizontal;
            border-left: 1px solid #FFF;
            &.first {
                border-left: 0 none;
            }
        }
    }
}

.widget-copyright {
    .pull-right();
    font-size: @font-size-small;
    font-weight: 200;
    color: #6e9aae;
}

/*
    Content
*/
.zone-content,
.modal-content {
    > .content-item > header > h1 {
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: @line-height-computed;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 32px;
            height: 3px;
            background-color: #6c9aad;
        }
    }
}

.read-more {
    font-weight: bold;
}

/*
    Pager
*/
ul.pager {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style-type: none;

    > li {
        border: 1px solid @brand-primary;
        margin: (@line-height-computed / 2) (@grid-gutter-width / 2);

        > a,
        > span {
            display: inline-block;
            padding: @padding-base-vertical @padding-base-horizontal;
        }

        > a:hover,
        > span {
            color: #FFF;
            background-color: @brand-primary;
            text-decoration: none;
        }
    }
}

/*
    Portfolio
*/
ul.taxonomy-portfolio,
ul.portfolio-children {
    padding: 0;
    list-style-type: none;

    > li {
        display: inline-block;
        padding: 0 @padding-small-horizontal;
        border-left: 2px solid @brand-primary;

        &.first {
            border-left: 0 none;
            padding-left: 0;
        }

        a {
            color: @brand-primary;
            text-transform: uppercase;

            &:hover,
            &:focus {
                text-decoration: none;
            }

            &:hover {
                font-weight: bold;
            }
        }
    }
}

.widget-portfolio > ul,
ul.media-library-picker-field-gallery,
.portfolio-term > .content-items {
    padding: 0;
    list-style-type: none;
    .make-row();

    > li {
        .make-xs-column(4);
        .make-md-column(3);
        padding-top: (@grid-gutter-width / 2);
        padding-bottom: (@grid-gutter-width / 2);

        img {
            .img-responsive();
        }

        a {
            display: block;
            position: relative;

            .zoom-overlay {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url('images/zoom.png') no-repeat center center fade(#6396ab, 80%);
            }

            &:hover {
                .zoom-overlay {
                    display: block;
                }
            }
        }
    }
}

/*
    Blog
*/
ul.blog-posts {
    .content-list();
}

.content-item.blog-post {
    > header > h1 {
        display: inline-block;
        + .published {
            padding-left: @padding-base-horizontal;
        }
    }

    .published {
        font-size: @font-size-h3;
        font-weight: 200;
    }
}

/*
    Projections
*/
.projection-page > ul {
    .content-list();
}

/*
    Card
*/
.card {
    background-color: #FFF;
    color: @gray-dark;
    padding: (@grid-gutter-width / 2) (@grid-gutter-width / 2);
    .box-shadow(0px 5px 10px 0px @gray-dark);

    h1, h2, h3, h4, h5, h6 {
        color: @brand-primary;
    }

    p {
        color: @gray-dark;
        font-weight: 200;
    }
}

/*
    Modal
*/
.modal-dialog {
    .close {
        position: absolute;
        top: 0;
        right: 0;
        color: @brand-primary;
        background-color: transparent;
        font-size: 2em;
        line-height: 1;
        .box-shadow(none);

        &:hover,
        &:focus {
            font-weight: bold;
            .box-shadow(none);
        }
    }
}

/*
    Pricing
*/
.pricing-list {
    .make-row();

    .pricing.content-item {
        .make-xs-column(6);
        .make-sm-column(4);
        text-align: center;
        margin-top: (@grid-gutter-width / 2);
        margin-bottom: (@grid-gutter-width / 2);

        > a {
            display: block;
            color: inherit;
            .card();

            &:hover,
            &:focus {
                text-decoration: none;
                .box-shadow(0px 8px 10px 0px @gray-dark);
            }
        }

        img {
            .img-responsive();
        }
    }
}

.zone-content,
.modal-content {
    > .content-item.pricing {
        .text-field.sub-title,
        .text-field.rate {
            display: block;
            font-weight: 200;
        }

        .text-field.sub-title {
            font-size: @font-size-h2;
            margin-top: @line-height-computed;
            margin-bottom: (@line-height-computed / 2);
        }

        .text-field.rate {
            font-size: @font-size-h3;
            margin-top: (@line-height-computed / 2);
            margin-bottom: (@line-height-computed / 4);
        }
    }
}

.modal-content {
    > .content-item.pricing {
        padding: @padding-large-vertical @padding-large-horizontal;
    }
}

.chip {
    float: left;
    background-color: @brand-primary;
    color: #FFF;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    font-size: @font-size-large;
    margin-right: 15px;
    margin-bottom: 15px;
}

blockquote {
    border: none;
    background-color: #86b5c3;
    margin-top: (@line-height-computed / 2);
    margin-bottom: (@line-height-computed / 2);
    padding: @padding-base-vertical @padding-base-horizontal;
    font-size: @font-size-base;
}

/*
    Overrides
*/
    .negative {
        #branding {
            .positive {
                display: none;
            }

            .negative {
                display: block;
            }
        }
    }

    .idkdo {
        body {
            background-color: #b8d1d6;
        }
    }

    .contact {
        body {
            color: #FFF;
        }

        input.text,
        textarea.text {
            color: #FFF;
        }

        .background-image {
            height: 100%;
        }

        #layout-navigation {
            background-color: fade(#497183, 73%);
        }
        // Main menu
        .widget-aside-first.widget-menu-widget {
            .menu {
                > li {
                    a {
                        color: darken(@brand-primary, 20%);
                    }

                    &.current,
                    &:hover {
                        a {
                            color: #FFF;
                        }
                    }

                    &.current {
                        border-left-color: #FFF;
                    }
                }
            }
        }
    }